@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  "COLORS" STYLES */

// font color
.text {
  &-default {
    color: $default !important;
  }
  &-action {
    color: $action !important;
  }
  &-primary {
    color: $primary !important;
  }
  &-primary-light {
    color: $primary-light !important;
  }
  &-secondary {
    color: $secondary !important;
  }
  &-success {
    color: $text-success !important;
  }
  &-danger {
    color: $danger-3 !important;
  }
  &-danger-3 {
    color: $danger-3 !important;
  }
  &-warning {
    color: $warning !important;
  }
  &-info {
    color: $info !important;
  }
  &-light {
    color: $light !important;
  }
  &-dark {
    color: $dark !important;
  }
  &-white {
    color: $white !important;
  }
  &-muted {
    color: var(--gray-500) !important;
  }
  &-blue {
    color: $blue !important;
  }
  &-blue-light {
    color: $blue-light-1 !important;
  }
  &-red {
    color: $red !important;
  }
  &-yellow {
    color: $yellow !important;
  }
  &-pink {
    color: #fc3654 !important;
  }
  &-orange {
    color: $orange !important;
  }
  &-whatsapp {
    color: $whatsapp !important;
  }
  &-black {
    color: $black !important;
  }
  &-black-strict {
    color: $black-strict !important;
  }
  &-white-strict {
    color: $white-strict !important;
  }
  &-upi {
    color: $upi-text !important;
  }
  &-card {
    color: $card-text !important;
  }
  &-cash {
    color: $cash-text !important;
  }
  &-purple {
    color: $purple !important;
  }
  &-purple2 {
    color: $purple2 !important;
  }
  &-orangeproduct {
    color: $orangeproduct !important;
  }
  &-green {
    color: $swiggy-green !important;
  }
  &-gray-50 {
    color: var(--gray-50) !important;
  }
  &-gray-100 {
    color: var(--gray-100) !important;
  }
  &-gray-200 {
    color: var(--gray-200) !important;
  }
  &-gray-300 {
    color: var(--gray-300) !important;
  }
  &-gray-400 {
    color: var(--gray-400) !important;
  }
  &-gray-500 {
    color: var(--gray-500) !important;
  }
  &-gray-600 {
    color: var(--gray-600) !important;
  }
  &-gray-700 {
    color: var(--gray-700) !important;
  }
  &-gray-800 {
    color: var(--gray-800) !important;
  }
  &-gray-900 {
    color: var(--gray-900) !important;
  }
  &-green-50 {
    color: $green50 !important;
  }
  &-green-100 {
    color: $green100 !important;
  }
  &-green-200 {
    color: $green200 !important;
  }
  &-green-300 {
    color: $green300 !important;
  }
  &-green-400 {
    color: $green400 !important;
  }
  &-green-500 {
    color: $green500 !important;
  }
  &-green-600 {
    color: $green600 !important;
  }
  &-green-700 {
    color: $green700 !important;
  }
  &-green-800 {
    color: $green800 !important;
  }
  &-green-900 {
    color: $green900 !important;
  }

  &-g-100 {
    color: $g100 !important;
  }
  &-g-200 {
    color: $g200 !important;
  }
  &-g-300 {
    color: $g300 !important;
  }
  &-g-400 {
    color: $g400 !important;
  }
  &-g-500 {
    color: $g500 !important;
  }
  &-forest {
    color: $forest !important;
  }
  &-plum {
    color: $pink !important;
  }
  &-lightgreen {
    color: $lightgreen !important;
  }
  &-darkred {
    color: $darkred !important;
  }
  &-pending {
    color: $orange400 !important;
  }
  &-dark-green {
    color: $green900 !important;
  }
  &-gold {
    color: lighten($gold, 0%) !important;
  }
  &-black-strict {
    color: $black-strict !important;
  }
  &-white-strict {
    color: $white-strict !important;
  }
  &-card-azure {
    color: $card-azure !important;
  }
}

// background color
.bg {
  &-default {
    background-color: $default !important;
  }
  &-none {
    background-color: none !important;
  }
  &-primary {
    background-color: $primary !important;
  }
  &-secondary {
    background-color: $secondary !important;
  }
  &-success {
    background-color: $success !important;
  }
  &-danger {
    background-color: $danger !important;
  }
  &-warning {
    background-color: $warning !important;
  }
  &-info {
    background-color: $info !important;
  }
  &-light {
    background-color: var(--gray-50) !important;
  }
  &-dark {
    background-color: $dark !important;
  }
  &-white {
    background-color: $white !important;
  }
  &-blue {
    background-color: $blue !important;
  }
  &-blue-light {
    background-color: $blue-light !important;
  }
  &-g-100 {
    background-color: $g100 !important;
  }
  &-g-200 {
    background-color: $g200 !important;
  }
  &-g-300 {
    background-color: $g300 !important;
  }
  &-g-400 {
    background-color: $g400 !important;
  }
  &-g-500 {
    background-color: $g500 !important;
  }
  &-red {
    background-color: $red !important;
  }
  &-r-100 {
    background-color: $r100 !important;
  }
  &-r-200 {
    background-color: $r200 !important;
  }
  &-r-300 {
    background-color: $r300 !important;
  }
  &-r-400 {
    background-color: $r400 !important;
  }
  &-r-500 {
    background-color: $r500 !important;
  }
  &-yellow {
    background-color: $yellow !important;
  }
  &-orange {
    background-color: $orange !important;
  }
  &-gray-1 {
    background-color: var(--gray-100) !important;
  }
  &-gray-2 {
    background-color: var(--gray-200) !important;
  }
  &-gray-3 {
    background-color: var(--gray-300) !important;
  }
  &-gray-4 {
    background-color: var(--gray-400) !important;
  }
  &-gray-5 {
    background-color: var(--gray-500) !important;
  }
  &-gray-6 {
    background-color: var(--gray-600) !important;
  }
  &-lblue {
    background-color: $lblue !important;
  }
  &-lgreen {
    background-color: $lgreen !important;
  }
  &-lblue2 {
    background-color: $lblue2 !important;
  }
  &-lyellow {
    background-color: $lyellow !important;
  }
  &-lred {
    background-color: $lred !important;
  }
  &-lpink {
    background-color: $lpink !important;
  }
  &-lpurple {
    background-color: $lpurple !important;
  }
  &-positive {
    background-color: $positive-bg !important;
  }
  &-negative {
    background-color: $negative-bg !important;
  }
  &-upi {
    background-color: $upi !important;
  }
  &-card {
    background-color: $card !important;
  }
  &-cash {
    background-color: $cash !important;
  }
  &-tds {
    background-color: $tds !important;
  }
  &-gray-25 {
    background-color: var(--gray-25) !important;
  }
  &-gray-50 {
    background-color: var(--gray-50) !important;
  }
  &-gray-75 {
    background-color: var(--gray-75) !important;
  }
  &-gray-100 {
    background-color: var(--gray-100) !important;
  }
  &-gray-200 {
    background-color: var(--gray-200) !important;
  }
  &-gray-300 {
    background-color: var(--gray-300) !important;
  }
  &-gray-400 {
    background-color: var(--gray-400) !important;
  }
  &-gray-500 {
    background-color: var(--gray-500) !important;
  }
  &-gray-600 {
    background-color: var(--gray-600) !important;
  }
  &-gray-700 {
    background-color: var(--gray-700) !important;
  }
  &-gray-800 {
    background-color: var(--gray-800) !important;
  }
  &-gray-900 {
    background-color: var(--gray-900) !important;
  }
  &-card-magenta {
    background-color: $card-magenta !important;
  }
  &-card-azure {
    background-color: $card-azure !important;
  }
  &-card-orange {
    background-color: $card-orange !important;
  }
  &-card-orange-dark {
    background-color: $card-orange-dark !important;
  }
  &-card-indigo {
    background-color: $card-indigo !important;
  }
  &-card-forest {
    background-color: $card-forest !important;
  }
  &-card-amount {
    background-color: $amount !important;
  }
  &-card-paid {
    background-color: $paid !important;
  }
  &-card-pending {
    background-color: $pending !important;
  }
  &-rose-gold {
    background-color: $rose-gold !important;
  }
  &-light-yellow {
    background-color: $lightyellow !important;
  }
  &-notify-danger {
    background-color: $notify-danger !important;
  }
  &-green {
    background-color: $swiggy-green !important;
  }
}

// border color
.border {
  &-default {
    border-color: $default !important;
  }
  &-primary {
    border-color: $primary !important;
  }
  &-secondary {
    border-color: $primary !important;
  }
  &-success {
    border-color: $success !important;
  }
  &-action {
    border-color: $action !important;
  }
  &-danger {
    border-color: $danger !important;
  }
  &-warning {
    border-color: $warning !important;
  }
  &-info {
    border-color: $info !important;
  }
  &-light {
    border-color: $light !important;
  }
  &-dark {
    border-color: $dark !important;
  }
  &-white {
    border-color: $white !important;
  }
  &-blue {
    border-color: $blue !important;
  }
  &-blue-light {
    border-color: $blue-light !important;
  }
  &-red {
    border-color: $red !important;
  }
  &-yellow {
    border-color: $yellow !important;
  }
  &-orange {
    border-color: $orange !important;
  }
  &-gray-100 {
    border-color: var(--gray-100) !important;
  }
  &-gray-2 {
    border-color: var(--gray-200) !important;
  }
  &-gray-3 {
    border-color: var(--gray-300) !important;
  }
  &-gray-4 {
    border-color: var(--gray-400) !important;
  }
  &-gray-5 {
    border-color: var(--gray-500) !important;
  }
  &-gray-6 {
    border-color: var(--gray-600) !important;
  }
  &-store {
    border-color: #fc3654 !important;
  }
  &-gray-50 {
    border-color: var(--gray-50) !important;
  }
  &-gray-100 {
    border-color: var(--gray-100) !important;
  }
  &-gray-200 {
    border-color: var(--gray-200) !important;
  }
  &-gray-300 {
    border-color: var(--gray-300) !important;
  }
  &-gray-400 {
    border-color: var(--gray-400) !important;
  }
  &-gray-500 {
    border-color: var(--gray-500) !important;
  }
  &-gray-600 {
    border-color: var(--gray-600) !important;
  }
  &-gray-700 {
    border-color: var(--gray-700) !important;
  }
  &-gray-800 {
    border-color: var(--gray-800) !important;
  }
  &-gray-900 {
    border-color: var(--gray-900) !important;
  }
  &-t-gray-100 {
    border-top: 2px solid var(--gray-100) !important;
  }
  &-card-magenta {
    border-color: $magenta !important;
  }
  &-card-azure {
    border-color: $azure !important;
  }
  &-card-orange {
    border-color: $pumpkin !important;
  }
  &-card-indigo {
    border-color: $indigo !important;
  }
  &-card-forest {
    border-color: $forest !important;
  }
  &-gold {
    border-width: 4px;
    border-color: $gold !important;
  }
}
