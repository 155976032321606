// Colors
@import 'themes/default.scss';

$primary: var(--primary);

$primary-light: var(--primary-light);
$primary-dark: var(--primary-dark);

$primary-border: var(--primary-border);

$primary-hover: var(--primary-hover);
$primary-active: var(--primary-active);

$white-strict: var(--white-strict);
$black-strict: var(--black-strict);

$gray-25: var(--gray-25);
$gray-75: var(--gray-75);
$gray-50: var(--gray-50); //#fbfbfa;
$gray-100: var(--gray-100);
$gray-200: var(--gray-200);
$gray-300: var(--gray-300);
$gray-400: var(--gray-400);
$gray-500: var(--gray-500);
$gray-600: var(--gray-600);
$gray-700: var(--gray-700);
$gray-800: var(--gray-800);
$gray-900: var(--gray-900);

$white: var(--white);
$black: var(--black);
//$darker: var(--darker);

$text: var(--black);

$cash: var(--cash);
$tds: var(--tds);
$tcs: var(--tcs);
$upi: var(--upi);
$card: var(--card);
$netbanking: var(--netbanking);
$paylater: var(--paylater);
$emi: var(--emi);
$cheque: var(--cheque);

$cash-hover: var(--cash-hover);
$tds-hover: var(--tds-hover);
$tcs-hover: var(--tcs-hover);
$upi-hover: var(--upi-hover);
$card-hover: var(--card-hover);
$netbanking-hover: var(--netbanking-hover);
$paylater-hover: var(--paylater-hover);
$emi-hover: var(--emi-hover);
$cheque-hover: var(--cheque-hover);

$cash-active: var(--cash-active);
$tds-active: var(--tds-active);
$tcs-active: var(--tcs-active);
$upi-active: var(--upi-active);
$card-active: var(--card-active);
$netbanking-active: var(--netbanking-active);
$paylater-active: var(--paylater-active);
$emi-active: var(--emi-active);
$cheque-active: var(--cheque-active);

$amount: var(--amount);
$paid: var(--paid);
$pending: var(--pending);

$cash-text: var(--cash-text);
$tds-text: var(--tds-text);
$tcs-text: var(--tcs-text);
$upi-text: var(--upi-text);
$card-text: var(--card-text);
$netbanking-text: var(--netbanking-text);
$paylater-text: var(--paylater-text);
$emi-text: var(--emi-text);
$cheque-text: var(--cheque-text);

$amount-text: var(--amount-text);
$paid-text: var(--paid-text);
$pending-text: var(--pending-text);

$success: var(--success);
$success-text: var(--success-text);

$success-hover: var(--success-hover);
$success-active: var(--success-active);

$warning: var(--warning);
$warning-text: var(--warning-text);

$warning-hover: var(--warning-hover);
$warning-active: var(--warning-active);

$danger: var(--danger);
$danger-text: var(--danger-text);

$danger-hover: var(--danger-hover);
$danger-active: var(--danger-active);

$lblue: var(--lblue);
$lred: var(--lred);
$lgreen: var(--lgreen);
$lyellow: var(--lyellow);
$lpurple: var(--lpurple);
$lpink: var(--lpink);
$lblue2: var(--lblue2);

$purple: var(--purple);

$positive-bg: var(--positive-bg);
$negative-bg: var(--negative-bg);

$card-magenta: var(--card-magenta);
$card-azure: var(--card-azure);
$card-forest: var(--card-forest);
$card-orange: var(--card-orange);
$card-white: var(--card-white);
$card-indigo: var(--card-indigo);
$card-orange-dark: var(--card-orange-dark);

$view-button: var(--view-button);
$record-payment: var(--record-payment);
$send-button: var(--send-button);

$view-button-hover: var(--view-button-hover);
$record-payment-hover: var(--record-payment-hover);
$send-button-hover: var(--send-button-hover);

$view-button-active: var(--view-button-active);
$record-payment-active: var(--record-payment-active);
$send-button-active: var(--send-button-active);

// Common ----------------------------------------------------------------------------------------

$border: $gray-200;

$blue: var(--primary);
$blue-light: #f0f3ff; //#f0f3ff;
$blue-dark: #2c60e4;
// $light-background: #f7fafc;
$swiggy-green: #06c167;
$text-success: #06c167;
$text-danger: #e85c4a;
$yellow: #fcedb9;
$posyellow: #ffe3ac;
$orange: #fabda5;
$red: #fed7d2;
$posred: #fdaba0;
$pink: #fd3995;
// $purple: #453473;
$purple2: #7356bf;
$green: #cbf4c9;
$posgreen: #b6dbb4;
$whatsapp: #25d366;
$whatsappdark: #075e54;
$gmail: #4285f4;
$blue-light-1: #f6fbfb;
$yellow-2: #ffe3ac;
$yellow-3: #ffecb3;
$forest: #0c8346;
$plum: #df49d8;
$lightgreen: #90ee90;
$lightyellow: #ffd40e;
$darkred: #fd2600;
$rose-gold: #e1bbd9;
$yellow-rose: #fef9f3;
$notify-danger: #ffd0d5;
$gold: #e2b836;
// $primary-light: #5276ff;
$spotify-yellow: #ffe818;
$orangeproduct: #ff3819;
$orange2: #ffe1d6;
$orangedark: #672a16;
$orangeStore: #fc3654;
$add-new: #fd5e76;

$danger-dark: #db0629;
$danger-bg: #dc043b;
$danger-hover: #b10f41;
$danger-light: #fff0f4;

//Spotify
$--color-azure: #3e8ef1;
$--color-storm: #a4c9d8;
$--color-tangerine: #ff4935;
$--color-citric: #cdf567;
$--color-sunflower: #ffbc4b;
$--color-pink: #ffd0d5;
$--color-salmon: #fb7ea8;

// Accent colors
$default: $gray-400;
$secondary: $gray-500;
// $success: #15be53;
$action: #5b61ff; //#4080FF#5b61ff 8c90ff;
$action-secondary: #533fdb; //#4080FF#5b61ff 8c90ff;
$pos: #a4419f;
$returns: #f1998e;
$pay: #15be53;
$info: $lblue;
// $warning: $yellow;
// $danger: $red;
$light: $lblue;
$dark: $black;
$danger-3: #e11900;

$azure: #027fff;
$pumpkin: #e66702;
$magenta: #dd04d1;
$indigo: #622aff;
$forest: #0c8346;

$blue50: var(--blue50);
$blue100: var(--blue100);
$blue200: var(--blue200);
$blue300: var(--blue300);
$blue400: var(--blue400);
$blue500: var(--blue500);
$blue600: var(--blue600);
$blue700: var(--blue700);
$blue800: var(--blue800);
$blue900: var(--blue900);

$green50: var(--green50);
$green100: var(--green100);
$green200: var(--green200);
$green300: var(--green300);
$green400: var(--green400);
$green500: var(--green500);
$green600: var(--green600);
$green700: var(--green700);
$green800: var(--green800);
$green900: var(--green900);

$g100: var(--g100);
$g200: var(--g200);
$g300: var(--g300);
$g400: var(--g400);
$g500: var(--g500);

$orange50: var(--orange50);
$orange100: var(--orange100);
$orange200: var(--orange200);
$orange300: var(--orange300);
$orange400: var(--orange400);
$orange500: var(--orange500);
$orange600: var(--orange600);
$orange700: var(--orange700);
$orange800: var(--orange800);
$orange900: var(--orange900);

$red50: var(--red50);
$red100: var(--red100);
$red200: var(--red200);
$red300: var(--red300);
$red400: var(--red400);
$red500: var(--red500);
$red600: var(--red600);

$r100: var(--r100);
$r200: var(--r200);
$r300: var(--r300);
$r400: var(--r400);
$r500: var(--r500);

// $card-magenta : #fdf1fc; //#fdf1fc
// $card-azure: #eef6ff; //#f3f9ff; //#f3f9ff #f8fbff
// $card-forest: #eef7f2; //#f3f9f6;   //#f8fbfa #f3f9f6
// $card-orange: #fef8f3;
// $card-white: #fffafb;
// $card-indigo: #f7f5ff; //#faf9ff
// $card-orange-dark: #fdf0e6;

// --violet: #8876fe;
// --plum: #8d1287;
// --indigo: #622aff;
// --azure: #027fff;
// --tomato: #f63e54;
// --persimmon: #e94b1b;
// --pumpkin: #e2620c;
// --orange: #e66702;
// --green: #0c9d46;
// --sea-green: #099b9d;
// --teal: #049cb7;
// --aqua: #039e8f;
// --sapphire: #3d44c3;
// --ultramarine: #0d1387;
// --magenta: #dd04d1;
// --tangelo: #ff3e17;
// --cobalt: #5451fb;
// --violet: #8876fe;
// --magenta: #d71bce;
// --plum: #8d1287;
// --indigo: #622aff;
// --grape: #6529f8;
// --azure: #027fff;
// --tomato: #f63e54;
// --orange: #e66702;
// --green: #0c9d46;
// --forest: #0c8346;
// --sea-green: #099b9d;
// --teal: #049cb7;
// --aqua: #039e8f;
// --moonstone: #2294b1;
// --midnight: #1b23b3;
// --sapphire: #3d44c3;
// --refreshing: #00D959;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;

// Font Family
$base-font-family: var(--kit-font-family);
$base-thermal-family: var(--kit-thermal-font-family);

// Font Size
$base-font-size: 15 !default;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{calc(floor(calc($px/$base) * 100) / 100)}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}

@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}

@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}

@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
