@import 'components/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* MENU COMPONENT */
.menu {
  background: $gray-50; //#f8faff !important;
  // box-shadow: 1px 0px $gray-100;
  transition: none !important;
  font-size: 1rem;
  font-weight: 500;

  .menuOuter {
    position: fixed;
    height: 100%;
    width: inherit;
    max-width: inherit;
  }

  .swipelogo {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 rem(20);
  }

  .collapsedlogo {
    display: none;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* LOGO */
  .logoContainer {
    height: 22.5px;
    display: flex;
    align-items: center;
    padding: 0 rem(20);
    margin-bottom: rem(18);

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: rem(24);
      width: 100%;
    }

    .name {
      color: $gray-900;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
      display: block;
    }

    .descr {
      margin-left: rem(13);
      padding-left: rem(13);
      height: rem(38);
      line-height: rem(38);
      border-left: 1px solid gray-200;
      font-size: rem(14);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ICON */
  .icon {
    text-align: center;
    position: relative;
    width: 20px;
    margin: 0 !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    line-height: 1 !important;
    color: $gray-500;
    // font-size: rem(14);
    font-weight: 400;
    transition: all 0.35s linear !important;

    &:hover {
      color: $gray-900;

      &:active {
        color: $gray-900;
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* COLLAPSE TRIGGER BUTTON */
  :global(.ant-layout-sider-trigger) {
    background: $gray-50;
    color: $text;
    padding: 0 1.2rem;
    transition: none;
    text-align: left;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* ANTD MENU CUSTOMIZATION */
  .navigation {
    padding: 0 0 0 1rem;
    transition: none !important;
    background: transparent;
    border-right: none;
    // color: $text;
    overflow-x: hidden !important;

    .title {
      margin-left: 0.5rem;
      // font-weight: 500;
      color: $gray-500;

      &:hover {
        color: $text;

        .icon {
          color: $text;
        }
      }
    }

    :global(.ant-menu-submenu > .ant-menu) {
      background: transparent;
    }

    :global(.ant-menu-submenu > .ant-menu > .ant-menu-item > .ant-menu-title-content) {
      padding: 0.01rem rem(1);
      line-height: 1.6rem;
    }

    :global(.ant-menu-submenu) {
      &:hover {
        .icon {
          transform: scale(1.08);
        }
      }

      // &:active {
      //   opacity: 0.7;
      // }
    }

    :global(.ant-menu-title-content) {
      &:hover {
        .title {
          color: $gray-900;
        }
        .icon {
          transform: scale(1.08);
        }
      }

      // &:active {
      //   opacity: 0.85;
      // }
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 0.55;
      background-color: $gray-900 !important;

      &:after {
        background-color: $gray-900 !important;
      }

      &:before {
        background-color: $gray-900 !important;
      }
    }

    :global(.ant-menu-inline .ant-menu-item),
    :global(.ant-menu-inline .ant-menu-submenu-title) {
      width: 100%;
    }

    :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      right: 4.5px;
      background-color: $gray-900 !important;

      &:after {
        background-color: $gray-900 !important;
      }
    }

    :global(.ant-menu-item-group-title) {
      color: $gray-500 !important;
      font-size: rem(12);
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    :global(.ant-menu-submenu-title) {
      height: auto !important;
      font-size: 1rem;
      line-height: 27px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-inline: 0;
      border-radius: 5px;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

      > span {
        color: $text;
      }

      &:active {
        background-color: #f2f2f2 !important;
        //opacity: 0.85;
      }

      > a {
        color: $text;

        &:hover {
          color: $text;

          .icon {
            color: $text;
          }
        }
      }
    }

    :global(.ant-menu-submenu) {
      border-radius: 5px;
      will-change: transform;
    }

    :global(.ant-menu-item) {
      height: auto !important;
      font-size: rem(15);
      line-height: 27px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-inline: 0;
      border-radius: 5px;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

      // &:hover {
      //   background-color: rgba(29, 29, 31, 0.06) !important;
      // }

      &:active {
        opacity: 0.85;
      }

      > span {
        color: $text !important;
      }

      > a {
        color: $text;

        &:hover {
          color: $text;

          .icon {
            color: $text;
          }
        }
      }
    }

    :global(.ant-menu-sub > li) {
      background: none !important;

      &:hover {
        background: none;
      }
    }

    :global(.ant-menu-sub) {
      padding-left: 15px !important;
      padding-top: 2.7px;
      padding-bottom: 9px;
    }

    :global(.ant-menu-submenu-selected) {
      font-weight: 500;

      > a {
        color: $action !important;

        &:hover {
          color: $action;

          .icon {
            color: $gray-500;
          }
        }
      }

      &:after {
        display: none;
      }

      .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
        background-color: $gray-900 !important;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $text !important;
      }
    }

    :global(.ant-menu-submenu-open),
    :global(.ant-menu-submenu-active),
    :global(.ant-menu-item-active) {
      background: none !important;
    }

    :global(.ant-menu-submenu-selected) > :global(.ant-menu-submenu-title) {
      background-color: rgba(29, 29, 31, 0.06) !important;
      font-weight: 700 !important;

      &:after {
        display: none;
      }
      .title {
        color: $gray-900;
      }

      .icon {
        text-align: center;
        position: relative;
        width: 20px;
        margin: 0 !important;
        color: $gray-900;
        font-size: 14px;
        &:hover {
          color: $gray-500;

          &:active {
            color: $gray-500;
          }
        }
      }
    }

    :global(.ant-menu-item-selected) {
      background-color: rgba(29, 29, 31, 0) !important;
      border-radius: 5px;
      color: #000000 !important;
      font-weight: 700 !important;

      &:after {
        display: none;
      }
      .title {
        color: $gray-900;
      }
      .icon {
        text-align: center;
        position: relative;
        width: 20px;
        margin: 0 !important;
        color: $gray-900;
        font-size: 16px;
        &:hover {
          color: $gray-900;

          &:active {
            color: $gray-900;
          }
        }
      }
    }

    :global(.ant-menu-item-active) {
      > span {
        color: rgba(29, 29, 31, 0.06) !important;
      }
    }

    :global(.ant-menu-item-disabled) {
      > span {
        color: gray-400;
      }
    }
  }

  .ant-menu-item.ant-menu-item-only-child,
  .ant-menu-submenu-title.ant-menu-item-only-child {
    margin-right: 0;
    padding-left: 40px !important;
    line-height: 15px !important;
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* COLLAPSED STYLES */
  &:global(.ant-layout-sider-collapsed) {
    @at-root {
      :global(.ant-menu-inline-collapsed-tooltip) .icon {
        display: none;
      }
    }

    // :global(.ant-menu-submenu) {
    //   &:hover {
    //     .icon {
    //       transform: scale(1.1) !important;
    //     }
    //   }
    // }

    // :global(.ant-menu-title-content) {
    //   &:hover {
    //     .icon {
    //       transform: scale(1.1);
    //     }
    //   }
    // }

    :global(.ant-menu-item-selected) {
      // outline: 1px solid $gray-500 !important;
      background-color: rgba(29, 29, 31, 0.06) !important;
      color: $gray-900;
    }

    :global(.ant-menu-submenu-selected) > :global(.ant-menu-submenu-title) {
      // outline: 1px solid $gray-500 !important;
      background-color: rgba(29, 29, 31, 0.06) !important;
      // line-height: 36px !important;
      color: $gray-900;

      &:hover {
        background-color: rgba(29, 29, 31, 0.06) !important;
      }
    }

    :global(.ant-layout-sider-trigger) {
      background: $gray-50;
      color: $text;
      padding: 0 rem(16);
      transition: none;
      text-align: center;
    }

    :global(.ant-menu-item) {
      padding: 5px !important;
      margin: 2px !important;
    }

    :global(.ant-menu-submenu-title) {
      padding: 5px !important;
      margin: 2px !important;
    }

    :global(.badge) {
      position: absolute;
      z-index: 1;
      top: 3px;
      right: 3px;
    }

    // .icon {
    //   padding-bottom: 12px;
    // }

    .swipelogo,
    .buyPro,
    .appdownload,
    .name,
    .line,
    .descr {
      display: none;
    }

    .navigation {
      padding: rem(0) rem(12);
    }

    .title {
      display: none;
    }

    :global(.ant-menu-item-group) {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        background: $gray-100;
      }
    }

    :global(.ant-menu-item-group-title) {
      visibility: hidden;
    }

    :global(.ant-menu-inline-collapsed-tooltip) .icon {
      display: none;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      transition: 5s ease-in;

      .icon {
        left: rem(11);
        top: rem(2);
        font-size: rem(18);
      }
    }

    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
      transition: 5s ease-in;

      .icon {
        left: rem(11);
        top: rem(2);
        font-size: rem(18);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /* BANNER COMPONENT */
  .banner {
    padding: rem(20);
    background: gray-100;
    border-radius: 3px;
    margin: rem(20);
    overflow: hidden;
    border: 1px solid $border;
  }

  :global(.ant-menu-inline-collapsed) + .banner {
    display: none;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
/* SETTINGS */
.unfixed {
  .menuOuter {
    position: relative;
  }
}

.buyPro {
  padding: rem(16);
  background: $card-azure;
  color: $text;
  border-radius: 9px;
  text-align: center;
  margin: rem(18) rem(16) rem(18);
  overflow: hidden;
}

.shadow {
  box-shadow: 0 0 100px -30px rgba(57, 55, 73, 0.3);
  z-index: 1;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* GRAY THEME */
.gray {
  background: $gray-100 !important;
}

/////////////////////////////////////////////////////////////////////////////////////////
/* DARK THEME */
.dark {
  color: $dark-gray-2;
  background: $gray-50 !important;

  .icon {
    color: $dark-gray-2;
  }

  .logoContainer {
    .name {
      color: $white;
    }

    .descr {
      color: $dark-gray-2;
      border-left: 1px solid $dark-gray-4;
    }
  }

  .banner {
    background: $dark-gray-4;
    border: 1px solid $dark-gray-4;
  }

  :global(.ant-layout-sider-trigger) {
    background: lighten($dark-gray-6, 5%);
  }

  .navigation {
    :global(.ant-menu-item-group-title) {
      color: darken($dark-gray-3, 10%) !important;
    }

    :global(.ant-menu-submenu-title) {
      color: $dark-gray-2;

      &:hover {
        color: $primary;
      }
    }

    :global(.ant-menu-item) {
      > span {
        color: $dark-gray-2;
      }

      > a {
        color: $dark-gray-2;

        &:hover {
          color: $primary;

          .icon {
            color: $primary;
          }
        }
      }
    }

    :global(.ant-menu-submenu-open),
    :global(.ant-menu-submenu-active),
    :global(.ant-menu-item-active) {
      background: $dark-gray-4 !important;
    }

    :global(.ant-menu-item-disabled) {
      > span {
        color: $dark-gray-3;
      }
    }
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-menu-item-group) {
      &:after {
        background: $dark-gray-4;
      }
    }
  }
}
